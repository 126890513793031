<template>
  <div v-if="!$_loading_state" class="p-linkOnlineStore">
    <!-- 連携時 -->
    <template v-if="isLinked">
      <LinkComplete v-if="page === 2" :user="formattedEcCustomer" />
      <AlreadyLink v-else :user="formattedEcCustomer" />
    </template>

    <!-- 未連携時 -->
    <template v-else>
      <LinkRelease v-if="hasReleased" @unlinked="onUnlink" />
      <InputForm v-else-if="page === 1" @linked="onLink" />
    </template>
  </div>
</template>

<script>
import { useNativeConnection } from '@/composables/useNativeConnection';
const { setMileage } = useNativeConnection();

export default {
  components: {
    InputForm: () => import('@/components/link/onlinestore/InputForm.vue'),
    AlreadyLink: () => import('@/components/link/onlinestore/AlreadyLink.vue'),
    LinkRelease: () => import('@/components/link/onlinestore/LinkRelease.vue'),
    LinkComplete: () => import('@/components/link/onlinestore/LinkComplete.vue')
  },

  data: () => ({
    page: 1,
    ecCustomer: null
  }),

  computed: {
    /**
     * オンラインストアと連携済みかどうか
     */
    isLinked() {
      return Boolean(this.ecCustomer);
    },

    /**
     * 連携解除画面を表示するかどうか
     */
    hasReleased() {
      // クエリパラメータに Key: `release` が含まれているかどうか
      return 'release' in this.$route.query;
    },

    formattedEcCustomer() {
      return {
        id: this.ecCustomer?.id,
        lastName: this.ecCustomer?.l_name,
        firstName: this.ecCustomer?.f_name
      };
    }
  },

  created() {
    this.getEcCustomer();
  },

  methods: {
    async getEcCustomer() {
      try {
        this.$_loading_start();

        const { data } = await this.$repositories('customer').getEcCustomer();
        this.ecCustomer = data;
      } catch {
        // EC未連携として扱う
      } finally {
        this.$_loading_stop();
      }
    },

    async onLink(data) {
      this.ecCustomer = data;
      if (data.mileage.plus_mileage > 0) {
        // マイル獲得ダイアログの呼び出し
        await setMileage({
          now_mileage: data.mileage.now_mileage,
          plus_mileage: data.mileage.plus_mileage,
          is_stage_up: data.mileage.rank_up,
          is_not_empty_present: !!data.present[0],
          stage_name_before: data.mileage.stage?.before.name,
          stage_name_after: data.mileage.stage?.after.name,
          stage_name_next: data.mileage.stage?.next.name,
          wait_for_load_complete: false
        });
      }
      this.page++;
    },

    onUnlink() {
      this.ecCustomer = null;
    }
  }
};
</script>
