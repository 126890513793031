var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$_loading_state
    ? _c(
        "div",
        { staticClass: "p-linkOnlineStore" },
        [
          _vm.isLinked
            ? [
                _vm.page === 2
                  ? _c("LinkComplete", {
                      attrs: { user: _vm.formattedEcCustomer },
                    })
                  : _c("AlreadyLink", {
                      attrs: { user: _vm.formattedEcCustomer },
                    }),
              ]
            : [
                _vm.hasReleased
                  ? _c("LinkRelease", { on: { unlinked: _vm.onUnlink } })
                  : _vm.page === 1
                  ? _c("InputForm", { on: { linked: _vm.onLink } })
                  : _vm._e(),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }